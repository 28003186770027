export default {
  top: {
    en: 'The On-Chain Crypto Bookmaker',
    cn: '区块链上的可信博彩',
  },
  // Sports
  'Politics': {
    en: 'Politics',
    cn: '选举',
  },
  'Football': {
    en: 'Football',
    cn: '足球',
  },
  'Basketball': {
    en: 'Basketball',
    cn: '篮球',
  },
  'Cricket': {
    en: 'Cricket',
    cn: '板球',
  },
  'Boxing': {
    en: 'Boxing',
    cn: '拳击',
  },
  'Ice Hockey': {
    en: 'Ice Hockey',
    cn: '冰球',
  },
  'Rugby Union': {
    en: 'Rugby Union',
    cn: '联合式橄榄球',
  },
  'Baseball': {
    en: 'Baseball',
    cn: '棒球',
  },
  'Rugby League': {
    en: 'Rugby League',
    cn: '联盟式橄榄球',
  },
  'American Football': {
    en: 'American Football',
    cn: '美式足球',
  },
  'MMA': {
    en: 'MMA',
    cn: '综合格斗',
  },
  'Tennis': {
    en: 'Tennis',
    cn: '网球',
  },
  'Dota 2': {
    en: 'Dota 2',
    cn: 'Dota 2',
  },
  'CS:GO': {
    en: 'CS:GO',
    cn: 'CS:GO',
  },
  'Counter-Strike 2': {
    en: 'Counter-Strike 2',
    cn: '反恐精英2',
  },
  'League of Legends': {
    en: 'League of Legends',
    cn: '英雄联盟',
  },
  'GameFi': {
    en: 'GameFi',
    cn: 'GameFi',
  },
  'Other': {
    en: 'Other',
    cn: '其他',
  },
  'Unique': {
    en: 'Unique Events',
    cn: '其他',
  },
}
