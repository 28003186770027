export default {
  all: {
    en: 'All',
    cn: '所有',
  },
  today: {
    en: 'Today',
    cn: '今天',
  },
  tomorrow: {
    en: 'Tomorrow',
    cn: '明天',
  },
  '1h': {
    en: '1h',
    cn: '1小时',
  },
  '3h': {
    en: '3h',
    cn: '3小时',
  },
  '6h': {
    en: '6h',
    cn: '6小时',
  },
}
