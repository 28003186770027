export default {
  text: {
    en: 'No events within selected filters',
    cn: '当前分类中没有项目',
  },
  reset: {
    en: 'Reset all',
    cn: '重置',
  },
}
