export default {
  title: {
    en: 'No events',
    cn: '没有项目',
  },
  text: {
    en: 'Oops, there is no events, try again later',
    cn: '没有项目，请稍后再试',
  },
}
